const addLastClickedParamToForm = () => {
  const form = document.querySelector('form.add-last-clicked');
  if (form) {
    const inputs = form.querySelectorAll('input');
    for(let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('click', (event) => {
        const input = event.target;
        const lastClickedInput = getOrCreateLastClickedInput(form);
        lastClickedInput.value = input.name;
      });
    }
  }
}

// Return the last clicked input if it exists, otherwise create it
const getOrCreateLastClickedInput = (form) => {
  let lastClickedInput = form.querySelector('input[name="last_clicked"]');
  if (!lastClickedInput) {
    lastClickedInput = document.createElement('input');
    lastClickedInput.type = 'hidden';
    lastClickedInput.name = 'last_clicked';
    form.appendChild(lastClickedInput);
  }
  return lastClickedInput;
}

document.addEventListener('turbolinks:load', addLastClickedParamToForm);