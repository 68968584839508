import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home-video"
export default class extends Controller {
  static targets = ["videoOne", "videoTwo", "categories"]

  connect() {
    this.playVideoOne()
    // on video one hover show the categories

    this.videoOneTarget.onclick = () => {
      this.toggleVideoOne();
    }

    this.videoTwoTarget.onclick = () => {
      this.toggleVideoTwo();
    }
  }

  playVideoOne() {
    this.videoOneTarget.classList.remove("hidden")
    this.videoOneTarget.play()
    this.videoTwoTarget.pause()
    this.videoTwoTarget.currentTime = 0
    this.videoTwoTarget.classList.add("hidden")

    // Remove any existing event listeners to avoid duplication
    this.videoOneTarget.onended = null
    this.videoOneTarget.onended = () => {
      this.playVideoTwo()
    }
  }

  playVideoTwo() {
    this.videoTwoTarget.classList.remove("hidden")
    this.videoOneTarget.pause()
    this.videoOneTarget.currentTime = 0
    this.videoOneTarget.classList.add("hidden")
    this.videoTwoTarget.play()

    // Remove any existing event listeners to avoid duplication
    this.videoTwoTarget.onended = null
    this.videoTwoTarget.onended = () => {
      this.playVideoOne()
    }
  }

  pauseVideoOne() {
    this.videoOneTarget.pause()
  }

  pauseVideoTwo() {
    this.videoTwoTarget.pause()
  }

  toggleVideoOne() {
    if (this.videoOneTarget.paused) {
      this.videoOneTarget.play()
    } else {
      this.videoOneTarget.pause()
    }
  }

  toggleVideoTwo() {
    if (this.videoTwoTarget.paused) {
      this.videoTwoTarget.play()
    } else {
      this.videoTwoTarget.pause()
    }
  }

  disconnect() {
    this.pauseVideoOne();
    this.pauseVideoTwo();

    this.videoOneTarget.onended = null;
    this.videoTwoTarget.onended = null;
  }
}
