document.addEventListener('turbolinks:load', () => {
    const header = document.querySelector('header');
    const popup = document.querySelector('#search-popup');

    if (popup) {
        popup.classList.add('trans'); // ensure this is always hidden on oad
        const headerHeight = header.offsetHeight;
        const popupHeight = popup.offsetHeight;
        popup.style.height = (popupHeight - headerHeight) + 'px';
    }
})