import Rails from '@rails/ujs';

const submitFormOnChange = () => {
  const inputs = document.querySelectorAll('.submit-form-on-change');

  if (inputs && inputs.length > 1) {
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      input.addEventListener('change', () => submitForm(input));
    }
  }
}

// Submit the form, or add a class to an element first
const submitForm = (element) => {
  const forms = document.querySelectorAll('form[data-remote]');
  for (let i = 0; i < forms.length; i++) {
    if (forms[i].contains(element)) {

      // First we check if we need to add a class to an element before submit
      const classToAdd = forms[i].getAttribute('data-class-to-add');

      // If there is we add the class, then wait a period of time and submit
      if(classToAdd) {
        const targets = document.querySelectorAll(forms[i].getAttribute('data-class-target'));
        const delay = forms[i].getAttribute('data-class-delay');

        if(targets.length > 0 && delay){
          for(let j = 0; j < targets.length; j++) {
            targets[j].classList.add(classToAdd);
          }
          
          setTimeout(
            () => Rails.fire(forms[i], 'submit'), 
            parseInt(delay)
          );
        }
      } else {

        // ... otherwise, just submit the form
        Rails.fire(forms[i], 'submit');
      }
    }
  }
}

document.addEventListener('turbolinks:load', submitFormOnChange)
// document.addEventListener('ajax:success', submitFormOnChange)
