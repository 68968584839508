// NOTE: some weird stuff started happening with the positioning of the image
// where 'fixed' position stopped working. As a workaround, before the image is 
// shown we store the images parent in a variable, move the image the <main>
// element and then position it relative to the mouse position. When we need to
// hide the image again we move it back to its original parent.

let image;
let parent

export const showImageLabel = (e) => {
  image = e.target.querySelector('img');
  parent = image.parentElement; // store parent so we can move image back
  if(!image) return;
  image.classList.add('show');
  positionImage(e);
  document.addEventListener('mousemove', positionImage)
}

export const hideImageLabel = () => {
  if(!image) return;
  image.classList.remove('show');
  document.removeEventListener('mousemove', positionImage);
  parent.appendChild(image); // move from <main> back to original parent
}

export const positionImage = (e) => {
  // ensure absolute position will be relative to main
  document.querySelector('main').appendChild(image);
  if(!image) return;
  let left = e.pageX;
  let top = e.pageY;
  const header = document.querySelector('header');
  top = top - header.offsetHeight - image.offsetHeight - 5;
  image.style.left = (left + 5) + 'px';
  image.style.top = top + 'px';
}

export const clearAllImageLabels = () => {
  const elements = document.querySelectorAll('.show-image-label-on-hover');
  for (let i = 0; i < elements.length; i++) {
    const image = elements[i].querySelector('img');
    if(image) image.classList.remove('show');
  }
}

const hoverImageLabel = () => {
  clearAllImageLabels();
  image = null;
  const elements = document.querySelectorAll('.show-image-label-on-hover');
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('mouseover', showImageLabel);
    elements[i].addEventListener('mouseleave', hideImageLabel);
  }
}

document.addEventListener('turbolinks:load', hoverImageLabel);

export default hoverImageLabel;




// let left = e.pageX;
//   let top = e.pageY;
//   const header = document.querySelector('header');
//   top = top - header.offsetHeight - image.offsetHeight - 5;
//   // image.style.left = (left + 5) + 'px';
//   // image.style.top = top + 'px';