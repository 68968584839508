import Rails from '@rails/ujs';

// Intercept click events on links and add a class to a target before navigating

const addClassBeforeNavigation = () => {
  const links = document.querySelectorAll('a');
  for(let i = 0; i < links.length; i++) {
    const link = links[i];

    // Skip if needed
    if(!link.classList.contains('add-class-before-navigation')) continue;

    const classToAdd = link.getAttribute('data-class-to-add');
    const targets = document.querySelectorAll(
      link.getAttribute('data-class-target')
    );
    
    const delay = link.getAttribute('data-class-delay');

    const isRemote = link.getAttribute('data-remote');

    const addClassBeforeClick = (e) => {
      // Stop the link from navigating
      if(isRemote) Rails.stopEverything(e); // for Rails UJS
      e.preventDefault();

      // Add the class to the target
      for(let j = 0; j < targets.length; j++) {
        targets[j].classList.add(classToAdd);
      }
      
      // // Remove this eventListener so we can click without retriggering
      link.removeEventListener('click', addClassBeforeClick);

      // // Wait a period of time, then click link
      setTimeout(
        () => Rails.fire(link, 'click'),
        parseInt(delay)
      );
    }

    if(classToAdd && targets.length > 0 && delay) {
      link.addEventListener('click', addClassBeforeClick);
    }
  }
}

document.addEventListener('turbolinks:load', addClassBeforeNavigation)

export default addClassBeforeNavigation;