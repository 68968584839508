import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="circle-populator"
export default class extends Controller {
  static targets = ["circleImage", "circleDescription", "circleLink", "listItem"]
  connect() {
  }

  populateCircle(event) {
    this.circleImageTarget.src = event.currentTarget.dataset.imgsrc || 'https://icecream-website-staging.s3.eu-west-2.amazonaws.com/placeholder.png'
    this.circleDescriptionTarget.innerHTML = event.currentTarget.dataset.description || 'No description'
  }
}
