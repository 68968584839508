// Trigger Animations
// Simple call setupAnimation with the trigger and details of 
// the elements you want to animate
// Trigger: is the element that will trigger the animation on hover
// Animated Elements: is an array of objects 
// containing selector, attribute and destination keys.
// - Selector: is the element you want to animate
// - Attribute: is the attribute you want to animate
// - Destination: is the value you want to animate to
// When the mouse leaves the trigger, the animated value will return 
// to it's original state.


const setupAnimation = (triggerSelector, animatedElements) => {
  const trigger = document.querySelector(triggerSelector);
  if(trigger){
    for(key in animatedElements){
      const { selector, attribute, destination } = animatedElements[key];
      const element = document.querySelector(selector);
      const startValue = element.getAttribute(attribute);
      trigger.addEventListener('mouseover', () => {
        element.setAttribute(attribute, destination);
      })
      trigger.addEventListener('mouseleave', () => {
        element?.setAttribute(attribute, startValue);
      })
    }
  }
}


document.addEventListener('turbolinks:load', () => {
  setupAnimation(
    '#we-are', 
    [
      {
        selector: '#we-are #Arm',
        attribute: 'transform',
        destination: "translate(-110 90.938) rotate(-56)"
      },
      {
        selector: '#we-are #Eyebrows',
        attribute: 'transform',
        destination: "translate(0 -2.394)"
      }
    ]
  );

  setupAnimation(
    'body.home #featured',
    [
      {
        selector: '#man-sitting-reading #Newspaper',
        attribute: 'transform',
        destination: "translate(-8.266 -2.108) scale(1.5)"
      },
      {
        selector: '#man-sitting-reading #Arm',
        attribute: 'transform',
        destination: "translate(0.779 -2.241) rotate(1)"
      }
    ]
  );

  setupAnimation(
    '#design-by-community',
    [
      {
        selector: '#CoffeeArm',
        attribute: 'transform',
        destination: "translate(-27.102 30.312) rotate(-19)"
        // destination: "translate(-57.102 60) rotate(-19)"
      }
    ]
  );

  setupAnimation(
    'body.clients #featured',
    [
      {
        selector: '#father-and-child #Arm',
        attribute: 'transform',
        destination: "translate(-90 170) rotate(-50)"

      }
    ]
  );
})

