document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('[data-toggle-focussed]');
  for(let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', () => {
      // if doesn't have focussed class, 
      // add it and remove it from all the other elements
      if(!elements[i].classList.contains('focussed')) {
        for(let j = 0; j < elements.length; j++) {
          elements[j].classList.remove('focussed');
        }
        elements[i].classList.add('focussed');
      }
    });
  }
})